import {useRoute} from 'vue-router'
import {getPublicInfo} from '@/api/user'

var url = require("url");

/**
 * 微信静默授权
 */

export function useWxAuthorizationSilence(itemurl) {
    let ua = window.navigator.userAgent.toLowerCase();
    let isWx = ua.match(/MicroMessenger/i) == 'micromessenger'
    if (!isWx) {
        // window.location.href="https://baidu.com"
    } else {
        let redirectUrl = encodeURI(itemurl)
        const urlObj = new URL(itemurl);
        const [appPhone, miniProgramPhone] = urlObj.searchParams.values()
        console.log(appPhone)
        localStorage.setItem('appPhone', appPhone)
        window.location.replace(`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx8be5e70285d97c03&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_base&state=${miniProgramPhone}#wechat_redirect`)
    }
}

export async function useWxAuthorization(itemurl = window.location.href) {
    let temp = url.parse(itemurl)
    const route = useRoute();
    if (route.query.code) {
        localStorage.setItem('wxAuthed', '');
        let res = await getPublicInfo(route.query.code)
        if (res.userInfo) {
            localStorage.setItem('m-userOpenId', res.userInfo.openId);
            localStorage.setItem('userInfo', JSON.stringify(res.userInfo));
        }
        if((localStorage.getItem('m-userOpenId'))&&(!localStorage.getItem('expiration'))){
            let setTime = new Date().getTime() + (1000 * 60 *24 *30);
            localStorage.setItem('expiration',setTime)
        }
        if ((localStorage.getItem('m-userOpenId'))&&checkExpired()) {
            return JSON.parse(localStorage.getItem('userInfo'))
        } else {
            let redirectUrl = encodeURI(itemurl)
            window.location.replace(`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx8be5e70285d97c03&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=${temp.query}#wechat_redirect`)
            return false
        }
    }
}

function checkExpired() {
    if(new Date().getTime() <= localStorage.getItem('expiration')){
        return true
    }else {
        localStorage.removeItem('expiration')
        localStorage.removeItem('m-userOpenId')
        localStorage.removeItem('userInfo')
        return  false
    }
}
